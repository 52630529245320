import * as React from "react";
import Header from "../../Components/Header/Header";
import CompanyInfo from "../../Components/CompanyInfo/CompanyInfo";
import Partnership from "../../Components/Partnership/Partnership";
import Footer from "../../Components/Footer/Footer";
import Seo from "../../Components/Seo/Seo";
import HamburgerMenu from "../../Components/HamburgerMenu/HamburgerMenu";

const CompanyInfoPage = () => {
  return (
    <div>
      <Seo locale="en-US" page="company info" />
      <Header lang="en-US" />
      <HamburgerMenu />
      <CompanyInfo lang="en-US" />
      <Partnership lang="en-US" />
      <Footer lang="en-US" />
    </div>
  );
};

export default CompanyInfoPage;
