import { graphql, useStaticQuery } from "gatsby";

const Query = () => {
  return useStaticQuery(graphql`
    query CompanyInfoQuery {
      allContentfulCompanyInfo {
        nodes {
          node_locale
          businessName
          businessNameTitle
          companyDataTitle
          mainTitle
          registeredOfficeTitle
          sdi
          shareCapital
          registeredOffice
          rea
          pec
          transparencyLink
          transparencyTitle
          transparencyLinkText
          transparencyDocument {
            file {
              url
            }
          }
          mainImageSmall {
            file {
              url
            }
            title
          }
          mainImage {
            file {
              url
            }
            title
          }
        }
      }
    }
  `);
};

export default Query;
