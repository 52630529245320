import * as React from "react";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import "./CompanyInfo.scss";
import Query from "./query";
import filePdfIcon from "../../assets/images/file_pdf.png";
import { filterDataByLanguage } from "../../Utils/Language";

const CompanyInfo = (props) => {
  const data = Query();
  const companyData = filterDataByLanguage(
    props.lang,
    data.allContentfulCompanyInfo
  );

  return (
    <div className="company-content-container">
      <Container fluid>
        <h1 className="company-title">
          {" "}
          {companyData && companyData.mainTitle}
        </h1>
      </Container>
      {companyData && (
        <ResponsiveImage
          desktopImage={companyData.mainImage.file.url}
          mobileImage={companyData.mainImageSmall.file.url}
          alt={companyData.mainImage.file.title}
          className="company-main-img img-lg-fluid"
        />
      )}

      {companyData && (
        <Container fluid className="company-details-container">
          <Row>
            <Col className="company-details-element pe-lg-3 mt-lg-4 mt-3 mb-4 col-lg-6 col-12">
              <h2 className="mb-3">{companyData.businessNameTitle}</h2>
              <h5>{companyData.businessName}</h5>
            </Col>
            <Col className="company-details-element pe-lg-3 mt-lg-4 mt-3 mb-4 col-lg-6 col-12">
              <h2 className="mb-3">{companyData.companyDataTitle}</h2>
              <div>{companyData.vat}</div>
              <div>{companyData.rea}</div>
              <div>{companyData.shareCapital}</div>
              <div>{companyData.pec}</div>
              <div>{companyData.sdi}</div>
            </Col>
            <Col className="company-details-element pe-lg-3 mt-lg-4 mt-3 mb-4 col-lg-6 col-12">
              <h2 className="mb-3">{companyData.registeredOfficeTitle}</h2>
              <div>{companyData.registeredOffice}</div>
            </Col>
            <Col className="company-details-element pe-lg-3 mt-lg-4 mt-3 mb-4 col-lg-6 col-12">
              <h2 className="mb-3">{companyData.transparencyTitle}</h2>
              <p>
                <a
                  className="align-middle"
                  href={companyData.transparencyDocument.file.url}
                >
                  <img
                    alt="PDF File Download"
                    className="me-1"
                    src={filePdfIcon}
                  />
                  {companyData.transparencyLinkText}
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

ResponsiveImage.propTypes = {
  className: PropTypes.string,
  desktopImage: PropTypes.string,
  mobileImage: PropTypes.string,
  alt: PropTypes.string,
};

function ResponsiveImage(props) {
  return (
    <picture className={props.className}>
      <source media="(min-width: 960px)" srcSet={props.desktopImage} />
      <img
        className={props.className}
        src={props.mobileImage}
        alt={props.alt}
      />
    </picture>
  );
}

CompanyInfo.propTypes = {
  lang: PropTypes.string.isRequired,
};

ResponsiveImage.propTypes = {
  className: PropTypes.string,
  desktopImage: PropTypes.string.isRequired,
  mobileImage: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default CompanyInfo;
